/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import Carousel from '../Carousel';

const BilateralImageCarousel = ({ pageBody }) => {
  const { _rawBilateralCarousel, _rawBilateralTextBlock, carouselStyle, title, bgColor } = pageBody;
  return (
    <Box>
      <Carousel
        bilateralCarousel={_rawBilateralCarousel}
        carouselStyle={carouselStyle}
        title={title}
        bgColor={bgColor}
        bilateralTextBlock={_rawBilateralTextBlock}
      />
    </Box>
  );
};

export default BilateralImageCarousel;
